import * as Yup from 'yup'
import cardValidator from '../../../../utils/creditCard/validator'

export const cardNumberValidation = Yup.string()
  .required('O número do cartão é obrigatório')
  .test('cardNumber', 'O número do cartão parece estar incorreto', (value) => {
    const cardInfo = cardValidator(value)

    if (cardInfo) {
      return cardInfo.isValid
    }
    return false
  })

export const cardNameValidation = Yup.string().required(
  'O campo nome é obrigatório'
)

export const cardMonth = Yup.string().required('O campo mês é obrigatório')
export const cardYear = Yup.string().required('O campo ano é obrigatório')
export const cardCvv = Yup.string()
  .required('O código de segurança é obrigatório')
  .test('validateCvv', 'O código de segurança parece incorreto', (value) => {
    return value.length >= 3
  })
export const installment = Yup.string().required(
  'Você precisa definir o número de parcelas'
)

const schema = Yup.object().shape({
  cardNumber: cardNumberValidation,
  cardName: cardNameValidation,
  cardMonth,
  cardYear,
  cardCvv,
  installment,
})

export default schema
