import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'

import Photo from '../../atoms/Photo'
import Button from '../../atoms/Button'

import * as S from './styles'

export default function CardDialogCancel({
  show,
  onClose,
  image,
  onRemoveCard,
}) {
  const { components } = useTheme()
  const coupleName = useSelector((state) => state.user.profile.couple_name)

  function handleClickOff({ target, currentTarget }) {
    if (target === currentTarget) {
      onClose()
    }
  }

  return show ? (
    <S.Modal onClick={handleClickOff}>
      <S.Container>
        <S.Info>
          <div>
            <S.Title>
              Surpreenda {coupleName} com um lindo cartão impresso 💌
            </S.Title>
            <S.Description>
              Após o grande dia, enviaremos a sua mensagem impressa para
              eternizar esse momento tão especial.{' '}
              <strong>
                Aproveite para demonstrar todo seu amor e carinho com essa linda
                lembrança.
              </strong>
            </S.Description>
            <Button
              variant={components.dialog.cardCancel.variant}
              handleClick={onClose}
            >
              Quero surpreender
            </Button>
            <S.ButtonExit onClick={onRemoveCard}>
              Não, prefiro ficar de fora.
            </S.ButtonExit>
          </div>
        </S.Info>
        <S.ImageWrapper>
          <Photo url={image} proportion="1:1" />
        </S.ImageWrapper>
      </S.Container>
    </S.Modal>
  ) : null
}

CardDialogCancel.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
}
