import * as Yup from 'yup'
import documentValidator from '../../../utils/documentValidator'

const cepRegex = /^\d{5}-\d{3}$/
const ssnRegex = /^\d{3}\.\d{2}\.\d{4}$/
const regexPassport = /^\d{3}\.\d{3}\.\d{4}$/

export const name = Yup.string()
  .required('O campo de nome é obrigatório.')
  .matches(/[A-zÀ-û]{2,}\s/, 'Informe seu nome completo.')

export const documentType = Yup.string().required(
  'O campo de documento de identificação é obrigatório'
)

export const document = Yup.string()
  .required('O campo de documento é obrigatório.')
  .test(
    'validateDocument',
    'O documento informado parece incorreto',
    (value, context) => {
      const numberClean = value.replace(/\D/gi, '')

      switch (context.parent.documentType) {
        case 'cpf':
          if (numberClean.length == 11) {
            return documentValidator(value)
          }
          return false
        case 'cnpj':
          if (numberClean.length == 14) {
            return documentValidator(value)
          }
          return false
        case 'ssn':
          return ssnRegex.test(value)
        case 'vat':
          return !!value.length
        case 'passport':
          return regexPassport.test(value)
      }

      return false
    }
  )

export const cep = Yup.string()
  .test('validateNull', 'O campo de cep é obrigatório.', (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  })
  .test('validateCep', 'O CEP informado parece incorreto', (value, context) => {
    if (!context.parent.isAddress) return true

    return cepRegex.test(value)
  })

export const street = Yup.string().test(
  'validateStreet',
  'O campo de endereço é obrigatório.',
  (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  }
)

export const number = Yup.string().test(
  'validateNumber',
  'O campo de número é obrigatório.',
  (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  }
)

export const neighborhood = Yup.string().test(
  'validateNeighborhood',
  'O campo de bairro é obrigatório.',
  (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  }
)

export const city = Yup.string().test(
  'validateCity',
  'O campo de cidade é obrigatório.',
  (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  }
)

export const state = Yup.string().test(
  'validateState',
  'O campo de estado é obrigatório.',
  (value, context) => {
    if (!context.parent.isAddress) return true

    return !!value.length
  }
)

const schema = Yup.object().shape({
  name,
  documentType,
  document,
  cep,
  street,
  neighborhood,
  city,
  state,
  number,
})

export default schema
