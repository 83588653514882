/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
} from 'react'
import { useSelector } from 'react-redux'

import * as gtm from '../../../lib/gtm'
import api from '../../../services/api'
import Radio from '../../atoms/Radio'
import PixWarning from '../../atoms/PixWarning'
import AddressSearch from '../AddressSearch'
import BankSlipWarning from '../../atoms/BankSlipWarning'
import CreditCardForm from './CreditCardForm'
import Recaptcha from '../../molecules/Recaptcha'
import apiGetMinimumValues from './requests/apiGetMinimumValues'
import Skeleton from '../../atoms/Skeleton'

import * as S from './styles'

const Payment = forwardRef((props, ref) => {
  const [paymentMethod, setPaymentMethod] = useState(null)
  const addressFormRef = useRef()
  const creditCardFormRef = useRef()
  const { products, card, infos, config } = useSelector((state) => state.cart)
  const { profile } = useSelector((state) => state.user)
  const { language } = useSelector((state) => state.language)
  const [isDisabledBankSlip, setDisabledBankSlip] = useState(true)
  const [isDisabledPix, setDisabledPix] = useState(true)
  const [isDisabledCreditCard, setDisabledCreditCard] = useState(true)
  const [minimumValues, setMinimumValues] = useState({})
  const recaptchaRef = useRef()
  const [recaptchaV3, setRecaptchaV3] = useState('')
  const [recaptchaV2, setRecaptchaV2] = useState('')
  const [recaptchaVersion, setRecaptchaVersion] = useState('v3')
  const [installment, setInstallment] = useState(0)

  const dispatchGtmAddPaymentInfo = (paymentType) => {
    const items = products.map((product, index) => ({
      index,
      item_id: String(product.id),
      item_name: product.name,
      currency: 'BRL',
      item_category: product.category.name,
      item_category2: 'Site do casal',
      item_list_id: product.item_list_id,
      item_list_name: product.item_list_name,
      price: product.price,
      quantity: product.quantity,
    }))

    if (card) {
      items.push({
        index: items.length,
        item_id: String(card.id),
        item_name: 'Cartão iCasei',
        currency: 'BRL',
        item_category: 'Cartão impresso',
        item_category2: 'Site do casal',
        price: card.price,
        item_variant: card.description,
      })
    }

    gtm.addPaymentInfo({
      value: products.reduce(
        (partialTotal, product) =>
          partialTotal + product.price * product.quantity,
        card?.price || 0
      ),
      items,
      payment_type: paymentType,
    })
  }

  const handleChangePaymentMethod = (target, isDisabled) => {
    if (!isDisabled) {
      recaptchaRef.current.resetRecaptchaV3()
      setPaymentMethod(target)
      dispatchGtmAddPaymentInfo(target)
    }
  }

  const handleSubmit = async () => {
    let paymentData
    const addressData = addressFormRef.current.validateForm()

    if (!addressData) {
      return {
        status: 'error',
        message:
          'Alguns campos não foram preenchidos corretamente. Verifique e tente novamente.',
      }
    }

    if (paymentMethod === 'credit-card') {
      paymentData = creditCardFormRef.current.validateForm()

      if (!paymentData) {
        return {
          status: 'error',
          message:
            'Alguns campos não foram preenchidos corretamente. Verifique e tente novamente.',
        }
      }
    }

    let paymentInfo
    if (paymentMethod === 'bank-slip') {
      paymentInfo = {
        payment_method: 'bank-slip',
        name: addressData.name,
        bank_slip: {
          document: {
            type: addressData.documentType,
            number: addressData.document,
          },
        },
      }
    } else if (paymentMethod === 'pix') {
      paymentInfo = {
        payment_method: 'pix',
        name: addressData.name,
        pix: {
          document: {
            type: addressData.documentType,
            number: addressData.document,
          },
        },
      }
    } else {
      paymentInfo = {
        payment_method: 'credit-card',
        credit_card: {
          number: paymentData.cardNumber.replace(/\D/g, ''),
          brand: paymentData.cardConfig.brand,
          name: paymentData.cardName,
          document: {
            type: addressData.documentType,
            number: addressData.document,
          },
          validity: {
            month: paymentData.cardMonth,
            year: paymentData.cardYear,
          },
          cvv: paymentData.cardCvv,
          installments: paymentData.installment,
        },
      }
    }

    // Mount the request body
    const data = {
      embedded_card: config.embeddedCard,
      products: products.map((product) => ({
        id: product.id,
        id_store: product.store_id,
        name: product.name,
        image: product.image,
        quantity: product.quantity,
        price: product.price,
      })),
      card,
      buyer: {
        name: infos.name,
        email: infos.email,
        phone: infos.phone,
        phone_country: infos.phoneCountry,
        message: infos.message,
      },
      payment_info: paymentInfo,
      address: addressData.address,
      couple_id: profile.couple_id,
      lang: language.route || 'pt_br',
      recaptcha_token:
        // eslint-disable-next-line no-nested-ternary
        paymentMethod === 'bank-slip' || paymentMethod === 'pix'
          ? ''
          : recaptchaVersion === 'v3'
          ? recaptchaV3
          : recaptchaV2,
      recaptcha_version: recaptchaVersion,
    }

    if (paymentMethod === 'bank-slip' || recaptchaV3) {
      try {
        const response = await api.post('/api/v1/payment', data)

        return {
          status: 'success',
          message: response.data,
          products,
          card,
        }
      } catch (error) {
        if (error?.response?.data?.is_robot) {
          setRecaptchaVersion('v2')
          return {
            status: 'error',
            message:
              error?.response?.data?.message ||
              'Por favor, confirme que você não é um robô!',
          }
        }
        return {
          status: 'error',
          message:
            error?.response?.data?.message ||
            'Houve um erro ao processar sua compra. Tente novamente mais tarde.',
        }
      } finally {
        if (recaptchaVersion === 'v2') {
          recaptchaRef.current.resetRecaptchaV2()
        } else {
          recaptchaRef.current.resetRecaptchaV3()
        }
      }
    }

    return paymentData
  }

  const total = useSelector(() => {
    const totalGifts = products.reduce(
      (partialTotal, product) =>
        partialTotal + product.price * product.quantity,
      0
    )

    const cardValue = card?.price || 0

    return totalGifts + cardValue
  })

  const getMinimumValues = () => {
    apiGetMinimumValues(profile.couple_id)
      .then((response) => {
        if (response.status === 200 && response?.data) {
          setMinimumValues({
            bankSlip: response.data.bank_slip,
            creditCard: response.data.credit_card,
            pix: response.data.pix,
          })
        } else {
          throw new TypeError('Houve um erro, favor tentar mais tarde!')
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err)
        setMinimumValues({ bankSlip: 10, creditCard: 10, pix: 10 })
      })
  }

  useImperativeHandle(ref, () => ({ handleSubmit }))

  useEffect(() => {
    if (Object.values(minimumValues).length) {
      setDisabledPix(minimumValues.pix > total)
      setDisabledBankSlip(minimumValues.bankSlip > total)
      setDisabledCreditCard(minimumValues.creditCard > total)
    }
  }, [total, minimumValues])

  useEffect(() => {
    getMinimumValues()
  }, [])

  const isLoading =
    !config.paymentMethods.length || !Object.values(minimumValues).length

  return (
    <S.Wrapper>
      <S.FormsWrapper>
        {isLoading &&
          Array.from({ length: 3 }).map((_, index) => (
            <S.PaymentOption key={index}>
              <S.Header disabled={false} selected>
                <S.OptionSelector>
                  <Skeleton
                    variant="circular"
                    style={{ width: 16, height: 16 }}
                  />
                  <Skeleton
                    variant="text"
                    style={{ fontSize: 12, width: 80, marginLeft: 12 }}
                  />
                </S.OptionSelector>
                <Skeleton
                  variant="circular"
                  style={{ width: 20, height: 20 }}
                />
              </S.Header>
            </S.PaymentOption>
          ))}

        {!!Object.values(minimumValues).length && (
          <>
            {config.paymentMethods.includes('pix') && (
              <S.PaymentOption data-testid="PaymentPix">
                <S.Header
                  selected={!paymentMethod || paymentMethod === 'pix'}
                  disabled={isDisabledPix}
                  onClick={() =>
                    handleChangePaymentMethod('pix', isDisabledPix)
                  }
                >
                  <S.OptionSelector>
                    <Radio
                      disabled={isDisabledPix}
                      id="pix"
                      name="payment-method"
                      value="pix"
                      checked={paymentMethod === 'pix'}
                      onChange={(event) =>
                        handleChangePaymentMethod(
                          event.target.value,
                          isDisabledPix
                        )
                      }
                    />
                    <S.OptionLabel htmlFor="pix">Pix</S.OptionLabel>
                  </S.OptionSelector>
                  <S.PixIcon />
                </S.Header>
                <S.Form selected={paymentMethod === 'pix'}>
                  <PixWarning />
                  {!isDisabledPix && paymentMethod === 'pix' && (
                    <>
                      <AddressSearch
                        isAddress={!!card}
                        ref={addressFormRef}
                        documentType="bank-slip"
                      />
                    </>
                  )}
                  {!isDisabledPix && (
                    <Recaptcha
                      ref={recaptchaRef}
                      setRecaptchaV2={setRecaptchaV2}
                      setRecaptchaV3={setRecaptchaV3}
                      recaptchaVersion={recaptchaVersion}
                      margin={'0'}
                    />
                  )}
                </S.Form>
              </S.PaymentOption>
            )}

            {config.paymentMethods.includes('master') && (
              <S.PaymentOption data-testid="PaymentCreditCard">
                <S.Header
                  selected={!paymentMethod || paymentMethod === 'credit-card'}
                  disabled={isDisabledCreditCard}
                  onClick={() =>
                    handleChangePaymentMethod(
                      'credit-card',
                      isDisabledCreditCard
                    )
                  }
                >
                  <S.OptionSelector>
                    <Radio
                      disabled={isDisabledCreditCard}
                      id="credit-card"
                      name="payment-method"
                      value="credit-card"
                      checked={paymentMethod === 'credit-card'}
                      onChange={(event) =>
                        handleChangePaymentMethod(
                          event.target.value,
                          isDisabledCreditCard
                        )
                      }
                    />
                    <S.OptionLabel htmlFor="credit-card">
                      Cartão de crédito
                    </S.OptionLabel>
                  </S.OptionSelector>
                  <S.CreditCardIcon />
                </S.Header>
                <S.Form selected={paymentMethod === 'credit-card'}>
                  <CreditCardForm
                    setCallbackInstallment={setInstallment}
                    ref={creditCardFormRef}
                  />
                  {!isDisabledCreditCard && paymentMethod === 'credit-card' && (
                    <>
                      <AddressSearch
                        isAddress={!!card || installment > 1}
                        ref={addressFormRef}
                        documentType="credit-card"
                      />
                    </>
                  )}

                  {!isDisabledCreditCard && (
                    <Recaptcha
                      ref={recaptchaRef}
                      setRecaptchaV2={setRecaptchaV2}
                      setRecaptchaV3={setRecaptchaV3}
                      recaptchaVersion={recaptchaVersion}
                      margin={'0'}
                    />
                  )}
                </S.Form>
              </S.PaymentOption>
            )}

            {config.paymentMethods.includes('bank_slip') && (
              <S.PaymentOption data-testid="PaymentBankSlip">
                <S.Header
                  selected={!paymentMethod || paymentMethod === 'bank-slip'}
                  disabled={isDisabledBankSlip}
                  onClick={() =>
                    handleChangePaymentMethod('bank-slip', isDisabledBankSlip)
                  }
                >
                  <S.OptionSelector>
                    <Radio
                      disabled={isDisabledBankSlip}
                      id="bank-slip"
                      name="payment-method"
                      value="bank-slip"
                      checked={paymentMethod === 'bank-slip'}
                      onChange={(event) =>
                        handleChangePaymentMethod(
                          event.target.value,
                          isDisabledBankSlip
                        )
                      }
                    />
                    <S.OptionLabel htmlFor="bank-slip">
                      Boleto bancário
                    </S.OptionLabel>
                  </S.OptionSelector>
                  <S.BankSlipIcon />
                </S.Header>
                <S.Form selected={paymentMethod === 'bank-slip'}>
                  <BankSlipWarning />
                  {!isDisabledBankSlip && paymentMethod === 'bank-slip' && (
                    <>
                      <AddressSearch
                        isAddress={!!card}
                        ref={addressFormRef}
                        documentType="bank-slip"
                      />
                    </>
                  )}
                  {!isDisabledBankSlip && (
                    <Recaptcha
                      ref={recaptchaRef}
                      setRecaptchaV2={setRecaptchaV2}
                      setRecaptchaV3={setRecaptchaV3}
                      recaptchaVersion={recaptchaVersion}
                      margin={'0'}
                    />
                  )}
                </S.Form>
              </S.PaymentOption>
            )}
          </>
        )}
      </S.FormsWrapper>
    </S.Wrapper>
  )
})

Payment.displayName = 'Payment'

export default Payment
