import React from 'react'

import * as S from './styles'

const PixWarning = () => {
  return (
    <S.Hint>
      <strong>O que você precisa saber antes de pagar com Pix:</strong>

      <p>
        • É necessário ter uma chave Pix cadastrada. Caso não possua, acesse o
        aplicativo da instituição financeira de sua preferência e realize o
        procedimento, leva apenas alguns segundos.
      </p>

      <p>
        • O pagamento será processado e debitado direto de sua conta corrente.
      </p>
    </S.Hint>
  )
}

export default PixWarning
