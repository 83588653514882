import React from 'react'

import * as S from './styles'

const BankSlipWarning = () => {
  return (
    <S.Hint>
      Para concluir sua compra realize o pagamento do boleto bancário e aguarde
      a compensação. (O prazo de compensação é de 48h úteis).
    </S.Hint>
  )
}

export default BankSlipWarning
