import styled, { css } from 'styled-components'

import { hexToRgba } from '../../../../utils/lightenColor'

import {
  Warning as WarningTypography,
  ContentMobile,
  Content as ContentTypography,
} from '../../../_settings/typography'

export const Form = styled.form.attrs({
  className: 'credit-card',
})``

export const Title = styled.h6.attrs({
  className: 'address-search__title',
})`
  ${() => css`
    ${ContentTypography};
    font-weight: 700;
    text-align: left;
    margin-bottom: 16px;
  `}
`

export const Flags = styled.div.attrs({
  className: 'credit-card-flags',
})`
  padding-top: 16px;
  padding-bottom: 32px;
`

export const FlagsText = styled.p.attrs({
  className: 'credit-card-flags__text',
})`
  ${({ theme }) => css`
    ${WarningTypography};
    color: ${hexToRgba(theme.colors.content, 0.38)};
  `}
`

export const FlagsList = styled.ul.attrs({
  className: 'credit-card-flags__list',
})`
  display: flex;
  margin-top: 8px;
`

export const FlagsItem = styled.li.attrs({
  className: 'credit-card-flags__item',
})`
  display: flex;
  width: 32px;

  & + & {
    margin-left: 8px;
  }
`

export const Line = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled.p`
  ${ContentMobile}

  display: flex;
  align-items: center;
`

export const Option = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  color: var(--content);
`

export const Col = styled.div`
  flex: 1;
  max-width: ${(props) => (props.width ? `${props.width}px` : '')};
  &:not(:last-child) {
    margin-right: 16px;
  }
`

export const Warning = styled.div.attrs(({ invalid }) => ({
  className: `text-input__hint${invalid && '--invalid'}`,
}))`
  ${WarningTypography};
  margin-top: 8px;
  color: ${(props) => (props.invalid ? 'var(--alert)' : '')};
`
