import React from 'react'
import propTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { formatPrice } from '../../../../../utils/format'

import * as S from './styles'

export default function Resume({ paymentInfo }) {
  const { language } = useSelector((state) => state.language)
  const returnTotalOfInstallments = () => {
    if (paymentInfo.installments > 1) {
      if (language.htmlTag === 'en') {
        return `at ${paymentInfo.installments}x from ${formatPrice(
          paymentInfo.total / paymentInfo.installments,
          language.htmlTag
        )}`
      }

      return `em ${paymentInfo.installments}x de ${formatPrice(
        paymentInfo.total / paymentInfo.installments,
        language.htmlTag
      )}`
    }

    return ''
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Header>
          <p>Descrição</p>
          <p>Quantidade</p>
          <p>Valor</p>
        </S.Header>
        {paymentInfo.products.map((product) => (
          <S.Row key={product.id}>
            <p>{product.name}</p>
            <p>{product.quantity}</p>
            <p>{formatPrice(product.price, language.htmlTag)}</p>
          </S.Row>
        ))}

        <S.Footer>
          <p />
          <p>Total</p>
          <span>
            <p>{formatPrice(paymentInfo.total, language.htmlTag)}</p>
            {returnTotalOfInstallments()}
          </span>
        </S.Footer>
      </S.Container>
    </S.Wrapper>
  )
}

Resume.propTypes = {
  paymentInfo: propTypes.shape({
    installments: propTypes.number,
    total: propTypes.number.isRequired,
    products: propTypes.array.isRequired, // eslint-disable-line
  }).isRequired,
}
