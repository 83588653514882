import styled, { css } from 'styled-components'
import { Warning } from '../../_settings/typography'

const paymentModifiers = {
  customBorderRadius: (radius) => css`
    border-radius: ${`${radius}px`};
  `,
}

export const Hint = styled.div.attrs({
  className: 'bank-slip__hint',
})`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    ${Warning};
    font-weight: 400;

    & > strong {
      font-weight: bold;
    }

    & > strong,
    & > p:nth-child(2) {
      margin-bottom: 16px;
    }

    padding: 16px;
    width: 100%;
    border: 1px solid var(--formBorder);
    margin-bottom: 16px;
    ${theme.components.payment.borderRadius &&
    paymentModifiers.customBorderRadius(theme)};
  `}
`
